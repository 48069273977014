import React from "react";

class Loading extends React.Component {
  state = {
    loadingDisplay: 0,
  };

  componentDidMount() {
    this.loadingInterval = setInterval(() => {
      const newLoadingDisplay =
        this.state.loadingDisplay >= 3 ? 0 : this.state.loadingDisplay + 1;
      this.setState({ loadingDisplay: newLoadingDisplay });
    }, 300);
  }

  componentWillUnmount() {
    clearInterval(this.loadingInterval);
  }

  render() {
    return (
      <div className={`loading`}>
        <div className="loading-content">
          Loading
          <span
            style={{
              opacity:
                this.state.loadingDisplay > 0 && this.state.loadingDisplay <= 3
                  ? 1
                  : 0,
            }}
          >
            .
          </span>
          <span
            style={{
              opacity:
                this.state.loadingDisplay > 1 && this.state.loadingDisplay <= 3
                  ? 1
                  : 0,
            }}
          >
            .
          </span>
          <span
            style={{
              opacity:
                this.state.loadingDisplay > 2 && this.state.loadingDisplay <= 3
                  ? 1
                  : 0,
            }}
          >
            .
          </span>
        </div>
      </div>
    );
  }
}
export default Loading;
