export const sources = [
  // {
  //   name: "Port de Quebec, Quebec, Canada",
  //   width: 560,
  //   height: 315,
  //   id: `JLJGvIRRWkw`,
  //   embed: `youtube`,
  //   cropTop: 2,
  //   cropBottom: 2,
  // },
  // {
  //   name: "Times Square, New York, USA",
  //   width: 560,
  //   height: 315,
  //   id: `mRe-514tGMg`,
  //   embed: `youtube`,
  // },
  // {
  //   name: "Las Vegas, USA",
  //   width: 560,
  //   height: 315,
  //   id: `Q7oswgzGIK0`,
  //   embed: `youtube`,
  //   cropTop: 10,
  //   cropBottom: 0,
  // },
  // {
  //   name: "Shibuya Crossing, Tokyo, Japan",
  //   width: 560,
  //   height: 315,
  //   id: `0vishEDtgdw`,
  //   embed: `youtube`,
  // },
  // {
  //   name: "Castricum, Amsterdam, Netherlands",
  //   width: 560,
  //   height: 315,
  //   id: `5rkyZrQv674`,
  //   embed: `youtube`,
  //   cropTop: 8,
  //   cropBottom: 17,
  // },
  {
    name: "De Gelderse Poort, Tolkamer, Netherlands",
    width: 560,
    height: 315,
    id: `Fegnalm7URQ`,
    embed: `youtube`,
    cropTop: 8,
    cropBottom: 0,
  },
  // {
  //   name: "Kip Caravan Rhoon, Vossenburg, Netherlands",
  //   width: 560,
  //   height: 315,
  //   id: `kYgxeD0m_rI`,
  //   embed: `youtube`,
  //   cropTop: 10,
  //   cropBottom: 0,
  // },
  {
    name: "Banzai Pipeline, Hawaii, USA",
    width: 560,
    height: 315,
    id: `DY5RYp4sxYc`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 30,
  },
  {
    name: "Vorontsov Port, Vorontsov, Ukraine",
    width: 560,
    height: 315,
    id: `LolxEeyJCKs`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 18,
  },
  {
    name: "Potemkin Stairs, Odessa, Ukraine",
    width: 560,
    height: 315,
    id: `OcrV35VFsok`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 19,
  },
  // {
  //   name: "Monument To Taras Shevchenko, Odessa, Ukraine",
  //   width: 560,
  //   height: 315,
  //   id: `nexYI3pyT9M`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 19,
  // },
  {
    name: "Santa Claus Village, Rovaniemi, Finland",
    width: 560,
    height: 315,
    id: `Cp4RRAEgpeU`,
    embed: `youtube`,
    cropTop: 2,
    cropBottom: 40,
  },
  // {
  //   name: "Place de la Palud, Lausanne, Switzerland",
  //   width: 560,
  //   height: 315,
  //   id: `XWYGDljzTP0`,
  //   embed: `youtube`,
  //   cropTop: 14,
  //   cropBottom: 0,
  // },
  {
    name: "International Space Station, Space",
    width: 560,
    height: 315,
    id: `XBPjVzSoepo`,
    embed: `youtube`,
    cropTop: 16,
    cropBottom: 10,
  },
  {
    name: "Isola 2000, Isola, France",
    width: 560,
    height: 315,
    id: `On0p2hgTK4E`,
    embed: `youtube`,
    cropTop: 14,
    cropBottom: 0,
  },
  {
    name: "Piazza del Popolo, Todi, Italia",
    width: 560,
    height: 315,
    id: `hiWqxsusnMw`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 0,
  },
  {
    name: "Piazza del Comune, Montefalco, Italia",
    width: 560,
    height: 315,
    id: `UklD1Cxcox8`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 0,
  },
  {
    name: "Playa del Inglés, Gran Canaria, Spain",
    width: 560,
    height: 315,
    id: `cvYNX6OONW4`,
    embed: `youtube`,
    cropTop: 8,
    cropBottom: 0,
  },
  {
    name: "Sestiere di Dorsoduro, Venice, Italy",
    width: 560,
    height: 315,
    id: `JqUREqYduHw`,
    embed: `youtube`,
    cropTop: 47,
    cropBottom: 11,
  },
  // {
  //   name: "Mallory Square, Florida, USA",
  //   width: 560,
  //   height: 315,
  //   id: `3vZaZj1PH-I`,
  //   embed: `youtube`,
  // },
  {
    name: "Lake Tahoe, California, USA",
    width: 560,
    height: 315,
    id: `thHgzWlveqs`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 30,
  },
  {
    name: "Dobó István Square, Eger, Hungary",
    width: 560,
    height: 315,
    id: `IELAhyaUKVk`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 0,
  },
  {
    name: "North Carolina, USA",
    width: 560,
    height: 315,
    id: `og8bbxl0iW8`,
    embed: `youtube`,
    cropTop: 2,
    cropBottom: 37,
  },
  {
    name: "Cracroft Island, Vancouver, Canada",
    width: 560,
    height: 315,
    id: `FiaDOY06VwI`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 37,
  },
  {
    name: "Broad Street, Oxford, UK",
    width: 560,
    height: 315,
    id: `St7aTfoIdYQ`,
    embed: `youtube`,
    cropTop: 10,
    cropBottom: 0,
  },
  // {
  //   name: "Bellas Artes, Ciudad de Mexico, Mexico",
  //   width: 560,
  //   height: 315,
  //   id: `XxTNW6dwJ9Q`,
  //   embed: `youtube`,
  //   cropTop: 52,
  //   cropBottom: 37,
  // },
  {
    name: "Plaça Mercadal, Reus, Spain",
    width: 560,
    height: 315,
    id: `L9HyLjRVN8E`,
    embed: `youtube`,
    cropTop: 13,
    cropBottom: 1,
  },
  // {
  //   name: "Saint Peter Square, Vatican City, Vatican",
  //   width: 560,
  //   height: 315,
  //   id: `3NoLUOlSshY`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 57,
  // },
  // {
  //   name: "Plaza de España, Vigo, Spain",
  //   width: 560,
  //   height: 315,
  //   id: `AngvRwz-FDs`,
  //   embed: `youtube`,
  //   cropTop: 17,
  //   cropBottom: 1,
  // },
  {
    name: "Transylvania, Romania, Europe",
    width: 560,
    height: 315,
    id: `2BDnAMR3GLg`,
    embed: `youtube`,
    cropTop: 32,
    cropBottom: 1,
  },
  {
    name: "Johnstone Strait, British Columbia, Canada",
    width: 560,
    height: 315,
    id: `5jcU2MVkEy8`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 38,
  },
  {
    name: "Amsterdam, Netherlands",
    width: 560,
    height: 315,
    id: `KGEekP1102g`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  // {
  //   name: "Kyoto, Japan",
  //   width: 560,
  //   height: 315,
  //   id: `XAqkW_tXAIc`,
  //   embed: `youtube`,
  //   cropTop: 28,
  //   cropBottom: 8,
  // },
  // {
  //   name: "Shibuya, Tokyo, Japan",
  //   width: 560,
  //   height: 315,
  //   id: `kQFo1bVV81c`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 1,
  // },
  {
    name: "Varbergs Square, Sweden",
    width: 560,
    height: 315,
    id: `bkvaeX5EoUs`,
    embed: `youtube`,
    cropTop: 27,
    cropBottom: 1,
  },
  {
    name: "Tonttula, Finland",
    width: 560,
    height: 315,
    id: `pR6l5t5sdtg`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  {
    name: "Bevagna, Umbria, Italia",
    width: 560,
    height: 315,
    id: `PfBPe5Jfzxo`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  {
    name: "Eger, Hungary",
    width: 560,
    height: 315,
    id: `IELAhyaUKVk`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  // {
  //   name: "Bergen Line, Norway",
  //   width: 560,
  //   height: 315,
  //   id: `_9ZVqdmaBvs`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 1,
  // },
  {
    name: "Bergen Line, Norway",
    width: 560,
    height: 315,
    id: `vPxAgn3EtDQ`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  {
    name: "Venice, Italy",
    width: 560,
    height: 315,
    id: `P393gTj527k`,
    embed: `youtube`,
    cropTop: 10,
    cropBottom: 44,
  },
  {
    name: "Venice, Italy",
    width: 560,
    height: 315,
    id: `mr-O8qqLqeM`,
    embed: `youtube`,
    cropTop: 39,
    cropBottom: 10,
  },
  {
    name: "Ultravision",
    width: 560,
    height: 315,
    id: `JbDs24AgrJs`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  {
    name: "Times Square",
    width: 560,
    height: 315,
    id: `4qyZLflp-sI`,
    embed: `youtube`,
    cropTop: 10,
    cropBottom: 1,
  },
  // {
  //   name: "Times Square",
  //   width: 560,
  //   height: 315,
  //   id: `MFT8oo-qvOA`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 1,
  // },
  // {
  //   name: "Times Square",
  //   width: 560,
  //   height: 315,
  //   id: `Bi1IxuOnRvA`,
  //   embed: `youtube`,
  //   cropTop: 10,
  //   cropBottom: 1,
  // },
  // {
  //   name: "Lamai, Koh Samui, Thailand",
  //   width: 560,
  //   height: 315,
  //   id: `17Deeq8N2e4`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 20,
  // },
  // {
  //   name: "Lanzarote, Spain",
  //   width: 560,
  //   height: 315,
  //   id: `phUQ3yVYFvA`,
  //   embed: `youtube`,
  //   cropTop: 15,
  //   cropBottom: 1,
  // },
  {
    name: "Jackson Hole, USA",
    width: 560,
    height: 315,
    id: `1EiC9bvVGnk`,
    embed: `youtube`,
    cropTop: 13,
    cropBottom: 1,
  },
  {
    name: "Farm Sanctuary",
    width: 560,
    height: 315,
    id: `0q0TXV8PyNY`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 37,
  },
  // {
  //   name: "La Grange, Kentucky USA",
  //   width: 560,
  //   height: 315,
  //   id: `pJ5cg83D5AE`,
  //   embed: `youtube`,
  //   cropTop: 15,
  //   cropBottom: 1,
  // },
  {
    name: "Casa",
    width: 560,
    height: 315,
    id: `ZEf_glmmcMM`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 15,
  },
  // {
  //   name: "Tokyo Tracks Railway",
  //   width: 560,
  //   height: 315,
  //   id: `QbKe8gCTszw`,
  //   embed: `youtube`,
  //   cropTop: 30,
  //   cropBottom: 1,
  // },
  {
    name: "Africam Nkorho Bush Lodge",
    width: 560,
    height: 315,
    id: `gUZjDCZEMDA`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 48,
  },
  {
    name: "Melbourne Platinum Apartments",
    width: 560,
    height: 315,
    id: `EpvIAopAYbw`,
    embed: `youtube`,
    cropTop: 10,
    cropBottom: 1,
  },
  {
    name: "Tokyo Live Camera Ch1",
    width: 560,
    height: 315,
    id: `6YRUWrkV2R4`,
    embed: `youtube`,
    cropTop: 25,
    cropBottom: 1,
  },
  {
    name: "Santa Monica Beach",
    width: 560,
    height: 315,
    id: `OWbI6WtlI-k`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 30,
  },
  {
    name: "DAXI",
    width: 560,
    height: 315,
    id: `z_mlibCfgFI`,
    embed: `youtube`,
    cropTop: 10,
    cropBottom: 1,
  },
  {
    name: "Shihmen Reservoir",
    width: 560,
    height: 315,
    id: `1OTZ9rjFv78`,
    embed: `youtube`,
    cropTop: 5,
    cropBottom: 1,
  },
  {
    name: "Bergen Line",
    width: 560,
    height: 315,
    id: `ZHgXfhiDIIM`,
    embed: `youtube`,
    cropTop: 1,
    cropBottom: 1,
  },
  // {
  //   name: "Bergen Line",
  //   width: 560,
  //   height: 315,
  //   id: `UwntV5pNWKA`,
  //   embed: `youtube`,
  //   cropTop: 34,
  //   cropBottom: 1,
  // },
  // {
  //   name: "Lamai, Koh Samui, Thailand",
  //   width: 560,
  //   height: 315,
  //   id: `KlNP-KAwTV4`,
  //   embed: `youtube`,
  //   cropTop: 1,
  //   cropBottom: 1,
  // },
];
