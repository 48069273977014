import React, { createRef } from "react";
import "./App.scss";
import { sources } from "./sources";
import Loading from "./loading";

var m_w = 123456789;
var m_z = 987654321;
var mask = 0xffffffff;
var seedValue = null;
seed(Math.random() * 1000);

// Takes any integer
function seed(i) {
  m_w = (123456789 + i) & mask;
  m_z = (987654321 - i) & mask;
}

// Returns number between 0 (inclusive) and 1.0 (exclusive),
// just like Math.random().
function random() {
  m_z = (36969 * (m_z & 65535) + (m_z >> 16)) & mask;
  m_w = (18000 * (m_w & 65535) + (m_w >> 16)) & mask;
  var result = ((m_z << 16) + (m_w & 65535)) >>> 0;
  result /= 4294967296;
  return result;
}

// const string = "HOLA"
// let numbers = [];
// string.split('').forEach(function(el){
//     console.log(el);
//     numbers.push(el.charCodeAt(0));
// });
// const res = numbers.reduce(function(a, b){
//   return a + b;
// });

//START
//video 1, 2, 3, 4
//video shown top and bottom
//video hidden next top next bottom
//WE SHOW TWO INITIAL VIDEOS AND LOAD ONE MORE TO REPLACE NEXT
//shown-top-1 shown-bottom-2 hidden-top-3 hidden-bottom-4
//shown-top-3 shown-bottom-2 hidden-top-NEW hidden-bottom-4
//shown-top-3 shown-bottom-4 hidden-top-1 hidden-bottom-NEW
//shown-top-1 shown-bottom-4 hidden-top-NEW hidden-bottom-2
//START AGAIN
//shown-top-3
//shown-top-next shown-bottom-3 shown-top-nextnext

const sourcesLength = sources.length;

const videoTopIndex = Math.round(Math.random() * (sourcesLength - 1));
// const videoTopIndex = sources.length - 1;
let videoBottomIndex = Math.round(Math.random() * (sourcesLength - 1));
let nextVideoTopIndex = Math.round(Math.random() * (sourcesLength - 1));
let nextVideoBottomIndex = Math.round(Math.random() * (sourcesLength - 1));

const videoTop = sources[videoTopIndex];

while (videoBottomIndex === videoTopIndex) {
  videoBottomIndex = Math.round(Math.random() * (sourcesLength - 1));
}

const videoBottom = sources[videoBottomIndex];

while (
  nextVideoTopIndex === videoTopIndex ||
  nextVideoTopIndex === videoBottomIndex
) {
  nextVideoTopIndex = Math.round(Math.random() * (sourcesLength - 1));
}

const nextVideoTop = sources[nextVideoTopIndex];

while (
  nextVideoBottomIndex === videoBottomIndex ||
  nextVideoBottomIndex === nextVideoTopIndex
) {
  nextVideoBottomIndex = Math.round(Math.random() * (sourcesLength - 1));
}

const nextVideoBottom = sources[nextVideoBottomIndex];
const initialState = {
  top: {
    "video-1": { active: true, index: videoTopIndex, data: { ...videoTop } },
    "video-2": {
      active: false,
      index: videoBottomIndex,
      data: { ...nextVideoTop },
    },
  },
  bottom: {
    "video-1": {
      active: true,
      index: nextVideoTopIndex,
      data: { ...videoBottom },
    },
    "video-2": {
      active: false,
      index: nextVideoBottomIndex,
      data: { ...nextVideoBottom },
    },
  },
  changeIndex: 0,
  canAutoplay: true,
  loading: true,
  seedValue: null,
  seedInput: false,
};

let interval;
const indexesPickers = {
  "05": 0,
  25: 0,
  45: 0,
  65: 0,
  85: 0,
  10: 1,
  30: 1,
  50: 1,
  70: 1,
  90: 1,
  15: 2,
  35: 2,
  55: 2,
  75: 2,
  95: 2,
  20: 3,
  40: 3,
  60: 3,
  80: 3,
  "00": 3,
};

let pause = false;

const seedInput = createRef();

class App extends React.Component {
  state = { ...initialState };

  setTransform = (video, videoRef) => {
    const { cropTop, cropBottom } = video;
    const cropSum = cropTop + cropBottom;
    const currHeight = videoRef.getBoundingClientRect().height;
    const relativeCropSum = (currHeight * cropSum) / video.height;

    const newHeight = currHeight - relativeCropSum;

    const scale = currHeight / newHeight;

    const ratio = currHeight / video.height;

    const translate = (cropTop * ratio - cropBottom * ratio) / 2;
    return { scale, translate };
  };

  swapVideos = (prevState, position, toActivate, toDeactivate) => {
    const otherPos = position === "top" ? "bottom" : "top";
    prevState[position][toActivate].active = true;
    prevState[position][toDeactivate].active = false;

    let newVideoIndex = Math.round(random() * (sourcesLength - 1));

    const otherPosActive = Object.values(prevState[otherPos]).find((el) => {
      return el.active === false;
    });

    while (
      newVideoIndex === prevState[position][toActivate].index ||
      newVideoIndex === otherPosActive.index
    ) {
      newVideoIndex = Math.round(random() * (sourcesLength - 1));
    }

    return newVideoIndex;
  };

  componentDidMount() {
    //VIDEO TOP
    const tempState = { ...initialState };
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.setState({ canAutoplay: false });
      return;
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 6000);

    Object.keys(tempState.top).forEach((el) => {
      const video = this.state.top[el].data;
      const { scale, translate } = this.setTransform(video, this.videotop1);

      tempState.top[el].data = { ...video, scale, translate };
    });
    Object.keys(tempState.bottom).forEach((el) => {
      const video = this.state.bottom[el].data;
      const { scale, translate } = this.setTransform(video, this.videobottom1);

      tempState.bottom[el].data = { ...video, scale, translate };
    });

    this.setState({ ...tempState });

    this.startInterval();
    document.addEventListener("keypress", (e) => {
      if (e.code === "Space") {
        this.setState({ seedInput: !this.state.seedInput });
        if (this.state.seedInput) seedInput.current.focus();
        setTimeout(() => {
          seedInput.current.value = "";
        }, 100);
      }
      if (
        e.key === "Enter" &&
        this.state.seedInput &&
        seedInput.current.value != ""
      ) {
        const string = seedInput.current.value;
        const numbers = [];
        string.split("").forEach(function (el) {
          numbers.push(el.charCodeAt(0));
        });
        const res = numbers.reduce(function (a, b) {
          return a + b;
        });
        seedInput.current.blur();
        this.setState({ seedValue: res, seedInput: false });
      }
    });
  }

  startInterval() {
    if (this.state.seedValue) {
      interval = setInterval(() => {
        const seconds = Math.floor(Date.now() / 1000);

        // 5, 25, 45, 65, 85  //TOP1
        // 10, 30, 50, 70, 90//BOTTOM1
        // 15, 35, 55, 75, 95//TOP2
        // 20, 40, 60, 80, 00//BOTTOM2

        if (seconds % 5 === 0) {
          const secondsNumbers = seconds.toString().split("");
          const lastNumber = secondsNumbers.pop();
          const beforeLastNumber = secondsNumbers.pop();
          const indexPicker = beforeLastNumber + lastNumber;
          const index = indexesPickers[indexPicker];
          seed(seconds + this.state.seedValue);
          if (pause || !this.state.canAutoplay || this.state.loading) return;

          // const index =
          //   this.state.changeIndex === 3 ? 0 : this.state.changeIndex + 1;

          const prevState = { ...this.state };

          let position, toActivate, toDeactivate;

          switch (index) {
            case 0: {
              position = "bottom";
              toActivate = "video-1";
              toDeactivate = "video-2";
              break;
            }
            case 1: {
              position = "top";
              toActivate = "video-2";
              toDeactivate = "video-1";
              break;
            }
            case 2: {
              position = "bottom";
              toActivate = "video-2";
              toDeactivate = "video-1";
              break;
            }
            case 3: {
              position = "top";
              toActivate = "video-1";
              toDeactivate = "video-2";
              break;
            }
            default: {
              break;
            }
          }

          const newVideoIndex = this.swapVideos(
            prevState,
            position,
            toActivate,
            toDeactivate
          );

          const video = sources[newVideoIndex];

          const { scale, translate } = this.setTransform(
            video,
            this[`video${position}1`]
          );

          prevState[position][toDeactivate].data = {
            ...video,
            scale,
            translate,
          };
          prevState[position][toDeactivate].index = newVideoIndex;

          this.setState({ ...prevState, changeIndex: index });
        }
      }, 1000);
    } else {
      interval = setInterval(() => {
        if (pause || !this.state.canAutoplay || this.state.loading) return;

        const index =
          this.state.changeIndex === 3 ? 0 : this.state.changeIndex + 1;

        const prevState = { ...this.state };

        let position, toActivate, toDeactivate;

        switch (index) {
          case 0: {
            position = "bottom";
            toActivate = "video-1";
            toDeactivate = "video-2";
            break;
          }
          case 1: {
            position = "top";
            toActivate = "video-2";
            toDeactivate = "video-1";
            break;
          }
          case 2: {
            position = "bottom";
            toActivate = "video-2";
            toDeactivate = "video-1";
            break;
          }
          case 3: {
            position = "top";
            toActivate = "video-1";
            toDeactivate = "video-2";
            break;
          }
          default: {
            break;
          }
        }

        const newVideoIndex = this.swapVideos(
          prevState,
          position,
          toActivate,
          toDeactivate
        );

        const video = sources[newVideoIndex];

        const { scale, translate } = this.setTransform(
          video,
          this[`video${position}1`]
        );

        prevState[position][toDeactivate].data = {
          ...video,
          scale,
          translate,
        };
        prevState[position][toDeactivate].index = newVideoIndex;

        this.setState({ ...prevState, changeIndex: index });
      }, 5000);
    }

    // document.addEventListener("keypress", (e) => {
    //   if (e.key === "Enter") {
    //     pause = !pause;
    //   }
    //   if (e.key === "s") {
    //     console.log(this.state);
    //   }
    //   if (e.key === "n") {
    //     const prevState = { ...this.state };

    //     const index =
    //       this.state.top["video-1"].index < sources.length - 1
    //         ? this.state.top["video-1"].index + 1
    //         : 0;
    //     const video = sources[index];
    //     console.log(index);
    //     console.log(video);
    //     console.log(sources.length);

    //     const { scale, translate } = this.setTransform(
    //       video,
    //       this[`videotop1`]
    //     );

    //     prevState["top"]["video-1"].data = {
    //       ...video,
    //       scale,
    //       translate,
    //     };
    //     prevState["top"]["video-1"].index = index;

    //     this.setState({ ...prevState });
    //   }
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.seedValue !== this.state.seedValue) {
      clearInterval(interval);
      this.startInterval();
    }
  }

  topPercentage = 70;

  render() {
    const height = this.state.top["video-1"].data.height;
    const width = this.state.top["video-1"].data.width;
    const ratio = (height / width) * 100;
    if (!this.state.canAutoplay) {
      return (
        <div className="App">
          <div className="mobile-notice">
            <div className="message">
              Esta obra solo puede ser visualizada en ordenadores
            </div>
          </div>
        </div>
      );
    }
    // console.log(this.state.loadingDisplay);
    return (
      <div className="App">
        {this.state.loading && <Loading></Loading>}
        {/* <div className="debug">{this.state.changeIndex}</div> */}
        <div
          className={`video-top video ${
            this.state.top["video-1"].active ? "" : "next"
          }`}
          // style={{ width: `${100 / (height / width)}vh` }}
          style={{
            paddingTop: `calc(${ratio}% / ${100 / this.topPercentage})`,
          }}
          ref={(videoBlock) => (this.videotop1 = videoBlock)}
        >
          {"name" in this.state.top["video-1"].data && (
            <iframe
              width={this.state.top["video-1"].data.width}
              height={this.state.top["video-1"].data.height}
              title={this.state.top["video-1"].data.name}
              src={`https://www.youtube.com/embed/${this.state.top["video-1"].data.id}?autoplay=1&controls=0&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope;"
              autoPlay
              allowFullScreen
              style={{
                transform: `scale(${
                  this.state.top["video-1"].data.scale
                }) translateY(${-this.state.top["video-1"].data.translate}px) `,
              }}
            ></iframe>
          )}
        </div>
        <div
          className={`video-bottom video ${
            this.state.bottom["video-1"].active ? "" : "next"
          }`}
          style={{
            width: `${100 / (height / width)}vh`,
            height: `${100 / (width / height)}vw`,
          }}
          ref={(videoBlock) => (this.videobottom1 = videoBlock)}
        >
          {"name" in this.state.bottom["video-1"].data && (
            <iframe
              width={this.state.bottom["video-1"].data.width}
              height={this.state.bottom["video-1"].data.width}
              title={this.state.bottom["video-1"].data.name}
              src={`https://www.youtube.com/embed/${this.state.bottom["video-1"].data.id}?autoplay=1&controls=0&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope;"
              autoPlay
              allowFullScreen
              style={{
                transform: `scale(${
                  this.state.bottom["video-1"].data.scale
                }) translateY(${-this.state.bottom["video-1"].data
                  .translate}px) `,
              }}
            ></iframe>
          )}
        </div>
        <div
          className={`video-top video ${
            this.state.top["video-2"].active ? "" : "next"
          }`}
          // style={{ width: `${100 / (height / width)}vh` }}
          style={{
            paddingTop: `calc(${ratio}% / ${100 / this.topPercentage})`,
          }}
          ref={(videoBlock) => (this.videotop2 = videoBlock)}
        >
          {"name" in this.state.top["video-2"].data && (
            <iframe
              width={this.state.top["video-2"].data.width}
              height={this.state.top["video-2"].data.width}
              title={this.state.top["video-2"].data.name}
              src={`https://www.youtube.com/embed/${this.state.top["video-2"].data.id}?autoplay=1&controls=0&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope;"
              autoPlay
              allowFullScreen
              style={{
                transform: `scale(${
                  this.state.top["video-2"].data.scale
                }) translateY(${-this.state.top["video-2"].data.translate}px) `,
              }}
            ></iframe>
          )}
        </div>
        <div
          className={`video-bottom video ${
            this.state.bottom["video-2"].active ? "" : "next"
          }`}
          style={{
            width: `${100 / (height / width)}vh`,
            height: `${100 / (width / height)}vw`,
          }}
          ref={(videoBlock) => (this.videobottom2 = videoBlock)}
        >
          {"name" in this.state.bottom["video-2"].data && (
            <iframe
              width={this.state.bottom["video-2"].data.width}
              height={this.state.bottom["video-2"].data.width}
              title={this.state.bottom["video-2"].data.name}
              src={`https://www.youtube.com/embed/${this.state.bottom["video-2"].data.id}?autoplay=1&controls=0&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope;"
              autoPlay
              allowFullScreen
              style={{
                transform: `scale(${
                  this.state.bottom["video-2"].data.scale
                }) translateY(${-this.state.bottom["video-2"].data
                  .translate}px) `,
              }}
            ></iframe>
          )}
        </div>
        <div className={`seed-input ${this.state.seedInput ? "active" : ""}`}>
          <input type="text" name="" id="" ref={seedInput} />
        </div>
      </div>
    );
  }
}

export default App;
